import React from 'react'

import { Helmet } from 'react-helmet'

import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Hushaug.no</title>
        <meta property="og:title" content="Hushaug.no" />
      </Helmet>
      <div className="home-banner">
        <h1 className="home-text">Hushaug.no</h1>
        <span className="home-text1">
          <span className="home-text2">
            Etterkommerne etter Anton og Ingeborg Jakobsen Torsvik
          </span>
          <br></br>
        </span>
        <span>
          For å komme inn i databasen må en ha brukernavn og passord. En må ta
          kontakt med en i arrangementskom. for å få denne tilgangen.
        </span>
        <div className="home-btn-group">
          <a
            href="https://www.hushaug.no/familietre/index.php"
            className="home-link button"
          >
            Familietre
          </a>
        </div>
      </div>
    </div>
  )
}

export default Home
